









import Vue from 'vue'

export default Vue.extend({
  name: 'ThisFeatureIsUnavailable',
  methods: {
    go_to_profile () {
      this.$router.push({ name: 'Profile' })
    }
  }
})
